var SiteNavigation = (function () {
	return function () {
		var pageNav = null;

		if (pageNav == null && window.PageNav) {
			pageNav = new SiteNavigationHandle();
			pageNav.init();

			this.refresh=function(userStatus){
				pageNav.refresh(userStatus);
			}
		}
	}

	function SiteNavigationHandle() {
		this.menus = [];
		this.pageNav = null;
		this.owlModal = {};
		var containerId = 'menu_{id}';
		SiteNavigationHandle.prototype.constructor = SiteNavigationHandle;

		SiteNavigationHandle.prototype.init = function (callback) {
			var that = this;
			if (window.PageNav) {
				if (window.pageName) {
					var navName = window.pageName ? window.pageName : 'home';
					if (this.pageNav == null)
					{
						var config = {
							navName: window.APP_ID ? navName + '_' + APP_ID : navName,
							navExpand: 0,
							navDataReady: function (menus) {
								that.menus = menus.map(function (item) {
									var result = item;
									result.domId = item.index + '_1';
									return result;
								});
								that.renderPlaceholder();
								var lazyload = new window.LazyLoad();
								$('.menu-section').each(function (idx, item) {
									lazyload.observe(item);
								})
								callback && callback(menus);
							},
							commonSolrRows: 2000,
							customProgramHandler: window.SOLR_HANDLER,
							customCategoryHandler: window.SOLR_HANDLER,
							thirdUserStatus: isTVE ? window.currentMVPD.status : null,
						}
						if (isTVE){
							config.nlUserStatus = null;
						}
						this.pageNav = new window.PageNav(config);
					}
				} else {
					NEU.logger.info('NEU PageNav is defined');
				}
			} else {
				NEU.logger.info('NEU PageNav is defined');
			}
		};

		SiteNavigationHandle.prototype.renderPlaceholder = function () {
			this.menus.forEach(function (menu) {
				var styleClass = menu.style ? menu.style : "";
				$('.site-nav-container').append($('<div data-lazytarget="' + menu.index + '" data-lazyload="LazyloadAction(\'' + menu.domId + '\')" id="' + containerId.replace('{id}', menu.domId) + '" class="menu-section ' + styleClass + '">' +
						'<div>' +
						'<div class="container has-desc">' +
						'<h3 class="owl-title"><a href="#">' + menu.name + '</a></h3>' +
						'</div>' +
						'<div class="loading-wrap"><div class="loading"/></div></div>' +
						'</div>'));
			})
			var that = this;
			window.LazyloadAction = function (index) {
				that.renderContainer(index);
			}
		}

		SiteNavigationHandle.prototype.refresh = function (userStatus) {
			var that = this;
			that.pageNav.filterMenusByThirdUserStatus(userStatus, function (menus) {
				that.clear();
				that.menus = menus.map(function (item) {
					var result = item;
					result.domId = item.index + '_2';
					return result;
				});
				that.renderPlaceholder();
				var lazyload = new window.LazyLoad();
				$('.menu-section').each(function (idx, item) {
					lazyload.observe(item);
				});
			});
		}

		SiteNavigationHandle.prototype.clear = function () {
			$('.site-nav-container').html('');
		}

		SiteNavigationHandle.prototype.filterChannelsByGrouping = function (channels, groupings) {
			if (groupings) {
				var groupingsArr = [], ret = [];

				if(groupings === 'all') {
					ret = channels;
				} else if(/^g:/.test(groupings)) {
					var _groupings = groupings.split(':');
					if (_groupings[1]) {
						groupingsArr = _groupings[1].split(',');
						ret = channels.filter(function (channel) {
							if (groupingsArr.indexOf(channel.grouping) != -1) {
								return true;
							}
						})
					}
				} else if(/^c:/.test(groupings)){
					var _channelsName = groupings.split(':'), _channels = JSON.parse(JSON.stringify(channels));
					if (_channelsName[1]) {
						groupingsArr = _channelsName[1].split(',');
						_channels.forEach(function (channel) {
							var obj = JSON.parse(JSON.stringify(channel));
							obj.list = [];

							channel.list.forEach(function (item) {
								var bool = groupingsArr.some(function (_name) {
									return item.seoname.toLowerCase().indexOf(_name.toLowerCase()) > -1;
								});
								if (bool) {
									obj.list.push(item);
								}
							})
							if(obj.list.length> 0) {
								return ret.push(obj);
							}
						});
					}
				} else {
					groupingsArr = groupings.split(',');
					ret = channels.filter(function (channel) {
						if (groupingsArr.indexOf(channel.grouping) != -1) {
							return true;
						}
					})
				}
				return ret;
			} else {
				return channels;
			}
		}
		id = 0;
		SiteNavigationHandle.prototype.getCurrentChannels = function (groupings, menu, callback) {
			var that = this;
			id++
			currentChannelHandle(function () {
				getChannels(function (channelsData) {
					var ret = [], data = that.filterChannelsByGrouping(channelsData, groupings);
					data.forEach(function (channel) {
						channel.list.forEach(function (item) {
							var _item = $.extend(true, {}, item);
							_item.programLink = LOC_SERVER + 'channels?channel=' + item.seoname;
							ret.push(_item);
						})
					});
					callback && callback(ret);
				});
			})
			renderProgramStats($('#' + containerId.replace('{id}', menu.domId)));
		}

		SiteNavigationHandle.prototype.renderContainer = function (id) {
			var that = this;
			this.menus.forEach(function (menu) {
				if (id !== undefined && menu.domId !== id) return;
				$('.site-nav-container #' + containerId.replace('{id}', menu.domId)).addClass('menu-section-content');
				if (menu.type === "13") {
					$('.site-nav-container #' + containerId.replace('{id}', menu.domId)).addClass("current_now_section");
					that.getCurrentChannels(window.FREE_CHANNEL_GROUPING, menu, function (list) {
						that.switchSectionDom(menu, list);
					});
				} else if (menu.type === "8_current") {
					$('.site-nav-container #' + containerId.replace('{id}', menu.domId)).addClass("free_channel_section");
					that.getCurrentChannels(menu.description, menu, function (list) {
						that.switchSectionDom(menu, list);
					});
				} else if (menu.type === "10") {
					$('.site-nav-container #' + containerId.replace('{id}', menu.domId)).addClass("view_history_section");
					programPositionChange();
					$(document).on('programPositionChange', function (event, data) {
						that.owlModal['#' + containerId.replace('{id}', menu.domId)] && that.owlModal['#' + containerId.replace('{id}', menu.domId)].trigger('destroy.owl.carousel')
						that.switchSectionDom(menu, data.list);
					})
				} else if (menu.type === "15" && menu.link === "category") {
					getWatchList('home', function (data) {
						that.switchSectionDom(menu, data.list);
					});
				} else {
					that.pageNav.getMenuDataByIndex(menu.index, function (menuDetailData) {
						if (menuDetailData.type === "15" && menuDetailData.link === "program") {
							if (menuDetailData.data && menuDetailData.data.contents && menuDetailData.data.contents.length > 0) {
								var list = menuDetailData.data.contents.map(function (item) {
									return item.id;
								}), retList = [];
								getProgramsOfPs(list, 'program', 'homeViewFavofProgram', function (_list) {
									retList = _list.map(function (item) {
										var program = menuDetailData.data.contents.filter(function (_item) {
											return _item.id == item.pid;
										})[0];
										var duration = getProgramViewProgress(item, program.position, program.completed);
										return {
											id: item.pid,
											seoName: item.seoName,
											seoname: item.seoName,
											image: LOC_CONTENT + item.image,
											name: item.name,
											dur: duration == 0 ? duration : (duration * 100 + '%'),
											purchaseType: 'purchase-type-' + (item.purchaseTypeId ? item.purchaseTypeId : 0),
											duration: item.runtime,
											position: program.position,
											completed: program.completed,
											tvRating: item.tvRating,
											click: true,
											isProgramTile: true,
											hasProgress: true,
											programLink: 'playProgram("' + item.seoName + '", null, this);'
										}
									}).sort(function (a, b) {
										return findIndexFromArrayById(list, a.id) - findIndexFromArrayById(list, b.id)
									})

									function findIndexFromArrayById(array, id) {
										return array.indexOf(id.toString());
									}

									that.switchSectionDom(menuDetailData, retList);
								});
							} else {
								menuDetailData.data = [];
								that.switchSectionDom(menuDetailData, []);
							}
						} else {
							that.switchSectionDom(menuDetailData);
						}
					});
				}
			})
		};

		SiteNavigationHandle.prototype.switchSectionDom = function (menu, list) {
			var options = {
				containerId: '#' + containerId.replace('{id}', menu.domId),
				hasDesc: false,
				hasFourItem: false,
				hasThreeItem: false,
			}, that = this;

			if (menu.type === "0") {
				options = $.extend({}, options, {
					hasFourItem: true,
					hasNoDesc: true,
					type: '5',
					channelLink: LOC_SERVER + 'category/' + menu.data.seoName,
					seoName: menu.data.seoName,
					title: menu.name,
				});
				if (menu.style) {
					options.style = menu.style;
				}
				options.list = that.processGenres(menu)
				that.renderDom(options);
			} else if (menu.type === "10") {
				options = $.extend({}, options, {
					hasFourItem: true,
					hasProgress: true,
					type: '4',
					channelLink: LOC_SERVER + 'account/history',
					title: menu.name,
					list: list,
				})
				that.renderDom(options);
			} else if (menu.type === "13" || menu.type === "8_current") {
				options = $.extend({}, options, {
					channelLink: LOC_SERVER + 'channels',
					hasDesc: true,
					hasFourItem: false,
					hasThreeItem: true,
					list: list,
					title: menu.name,
					type: "1",
				})
				that.renderDom(options);
			} else if (menu.type === "15") {
				options = $.extend({}, options, {
					hasFourItem: true,
					hasNoDesc: true,
					list: list,
					title: menu.name,
					type: menu.link === "program" ? "4" : "3"
				});
				that.renderDom(options);
			} else if (menu.type === "9") {
				options = $.extend({}, options, {
					hasFourItem: true,
					hasNoDesc: true,
					title: menu.name,
					type: "2"
				});
				options.list = that.processRecommened(menu);
				that.renderDom(options);
			} else if (menu.type === '7') {
				options = $.extend({}, options, {
					hasFourItem: true,
					hasNoDesc: true,
					type: '5',
					title: menu.name,
				});
				if (menu.style) {
					options.style = menu.style;
				}
				options.list = that.processGenres(menu);
				that.renderDom(options);
			}
		}

		SiteNavigationHandle.prototype.renderDom = function (data) {
			var myTemplate = Handlebars.compile($("#channel-content").html());
			$(data.containerId).html(myTemplate(data));
			this.initOwl(data);
		};

		SiteNavigationHandle.prototype.initOwl = function (options) {
			if (options.list.length > 0) {
				var _owlConfig = {
					nav: true,
					dots: false,
					margin: 30,
					navText: ['', ''],
					lazyLoad: true,
					responsive: {
						0: {
							items: 1
						},
						320: {
							items: 2
						},
						768: {
							items: 2
						},
						992: {
							items: 3
						},
						1200: {
							items: 4
						}
					}
				}
				if (options.type == '1' || options.type == '0') {
					_owlConfig.lazyLoad = false;
					_owlConfig.autoWidth = true;
				} else if (options.type == '4') {
					_owlConfig.responsive['0'].autoWidth = true;
					_owlConfig.responsive['320'].autoWidth = true;
				} else if (options.style) {
					_owlConfig.responsive['1200'].items = 3;
				}
				if (this.owlModal[options.containerId]) {
					this.owlModal[options.containerId].trigger('destroy.owl.carousel');
				}
				this.owlModal[options.containerId] = $('.loop', $(options.containerId)).owlCarousel(_owlConfig);
			} else {
				$(options.containerId).removeClass('menu-section').hide();
			}
		}
		SiteNavigationHandle.prototype.processRecommened = function (menu) {
			if (menu.link === 'program') {
				return menu.data.map(function (item) {
					return {
						id: item.pid,
						image: LOC_CONTENT + item.image,
						name: item.name,
						purchaseType: (item.progType ? 'purchase-type-' + item.progType : 0),
						click: true,
						programLink: 'playProgram("' + item.seoName + '", null, this);'
					};
				})
			} else if (menu.link === 'category') {
				return menu.data.map(function (item) {
					return {
						id: item.catId,
						image: LOC_CONTENT + 'categories/' + item.catId + '_es.jpg',
						name: item.name,
						programLink: LOC_SERVER + 'category/' + item.seoName,
						programTag: item.style ? LOC_RESOURCE + SITE_ID + '/site_4/images/' + item.style.toLowerCase() + '_eb.png' : ''
					};
				})
			}
		}
		SiteNavigationHandle.prototype.processGenres = function (menu) {
			var isProgram = false, list = [];
			if (menu.type === '0') {
				list = menu.data.isLeaf ? menu.data.programs : menu.data.subCategories;
				isProgram = menu.data.isLeaf ? true : false;
			} else if (menu.type === '7') {
				list = menu.data;
				isProgram = menu.dataType === 'program' ? true : false;
			}

			var ret = list.map(function (item) {
				if (isProgram) {
					return {
						id: item.pid,
						image: LOC_CONTENT + item.image,
						name: item.name,
						programLink: LOC_SERVER + 'video/' + item.seoName,
						programTag: item.style ? LOC_RESOURCE + SITE_ID + '/site_4/images/' + item.style.toLowerCase() + '_eb.png' : ''
					}
				} else {
					return {
						id: item.catId,
						image: LOC_CONTENT + 'categories/' + item.catId + '_es.jpg',
						name: item.name,
						programLink: LOC_SERVER + 'category/' + item.seoName,
						programTag: item.style ? LOC_RESOURCE + SITE_ID + '/site_4/images/' + item.style.toLowerCase() + '_eb.png' : ''
					}
				}
			});
			return ret;
		}
	}

})();

/*
* DTC:  VIP User, Subscriber, Sub and MVPD, Trial Subscriber, Subscriber IAP
* TVE:  MVPD Subscriber, Trial and MVPD, Reg and MVPD
* Free:  Anonymous, Member
* */
function initHomeDL() {
	var type = window.pageName + '_' + window.APP_ID;
	if (userType === 'anonymous' || userType === 'member') //Free
	{
		type += '_free';
	}
	var url = LOC_SERVER + 'home?format=json&nocatstruct=true&dlname=' + type;

	var dom = '<a class="item js-dl-item {hasIcon}" href="{link}" data-id="{id}" data-index="{index}" data-seoname="{seoname}" data-type="{type}">' +
			'<img class="img js-img" src="{image}" />' +
			'<div class="dl-mask"></div>' +
			'<div class="info">' +
			'<div class="container">' +
			'<h5 class="title">{title}</h5>' +
			'<p class="desc">{description}</p>' +
			'<div class="btn {hideBtn}">' +
			'<div class="btn-content">' +
			'<i class="icon" data-style="{style}" style="{icon}"></i>' +
			'<span class="text">{subTitle}</span>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</div>' +
			'</a>';

	initDL(url, ".js-home-dl", dom);
}

function initDL(url, container, dom) {
	NEU.util.getJSON(url, function (data) {
		dataHandel(data);
	}, function () {
		console.log('Get Data Failed!');
	});

	function dataHandel(obj) {
		if (obj.dynamicLead && obj.dynamicLead.length > 0) {
			var items = [];
			var programIds = [];
			var mql = window.matchMedia("(orientation: portrait)");
			obj.dynamicLead.forEach(function (currentItem, currentIndex, arr) {
				var item = {};
				item.id = currentItem.id;
				item.title = currentItem.title;
				item.description = currentItem.description;
				item.subTitle = currentItem.subTitle;
				item.hideBtn = item.subTitle === undefined || item.subTitle === '' || item.subTitle === null ? 'btn-hide' : '';
				item.style = currentItem.style;
				item.type = currentItem.type;
				item.image = detectOrientForDl(mql, item.id);
				item.link = 'javascript: void(0)';
				item.icon = currentItem.style ? (LOC_CONTENT + 'icons/' + currentItem.style + '.png') : '';
				item.platform = currentItem.platform ? (parseInt(currentItem.platform) & 1) : 0;
				item.showItem = (item.platform === 1) && (currentItem.userType === undefined || ((currentItem.userType === 0 && !isLoggedIn) || (currentItem.userType === 1 && isLoggedIn && !isSubscriber) || (currentItem.userType === 2 && isLoggedIn) || (currentItem.userType === 3 && isSubscriber)));

				if (item.type === "1") //	Program
				{
					if (currentItem.program) {
						item.seoName = currentItem.program.seoName;
						item.purchaseType = currentItem.program.purchaseTypeId || null;
						item.programId = currentItem.program.id;
						item.showItem && programIds.push(currentItem.program.id);
					}
				} else if (item.type === '2') //Link
				{
					item.seoName = currentItem.link;
				} else if (item.type === "4") //Channel
				{
					item.seoName = currentItem.channel.seoName;
					item.seoName = item.seoName.toLowerCase();
				} else if (item.type === "6") //Category
				{
					item.seoName = currentItem.category.seoName;
					item.seoName = item.seoName.toLowerCase();
				}
				item.showItem && items.push(item);
			})

			if (programIds.length > 0) {
				var programDetailUrl = LOC_SERVER + 'service/programs?format=json&ids=' + programIds.join();
				NEU.util.getJSON(programDetailUrl, function (data) {
					data.programs.forEach(function (programItem) {
						items.forEach(function (currentItem) {
							if (currentItem.programId && (programItem.id === currentItem.programId)) {
								if (programItem.tvRating == null || programItem.tvRating == '' || programItem.tvRating == 'unrated' || programItem.tvRating == '#N/A' || programItem.tvRating == 'NR' || programItem.tvRating == 'N/A') {
									currentItem.tvRating = 'TV-MA';
								} else {
									currentItem.tvRating = programItem.tvRating;
								}
								currentItem.link = "javascript: customPlayProgram('" + currentItem.seoName + "'," + currentItem.purchaseType + ",'" + currentItem.tvRating + "');";
							}
						})
					})
					renderHTML(items);
				}, function () {
					console.log('Get Program Data Failed!');
				});

			} else if (items.length > 0) {
				renderHTML(items);
			}

			function renderHTML(itemsData) {
				var html = '';
				itemsData.forEach(function (currentItem, currentIndex, arr) {
					html += dom.replace(/\{title\}/, currentItem.title)
							.replace(/\{description\}/, currentItem.description)
							.replace(/\{subTitle\}/, currentItem.subTitle)
							.replace(/\{image\}/, currentItem.image)
							.replace(/\{seoname\}/, currentItem.seoName)
							.replace(/\{type\}/, currentItem.type)
							.replace(/\{link\}/, currentItem.link)
							.replace(/\{style\}/, currentItem.style)
							.replace(/\{hasIcon\}/, currentItem.icon ? 'has-icon' : '')
							.replace(/\{icon\}/, currentItem.icon ? 'background-image: url(' + currentItem.icon + ');' : 'display: none;')
							.replace(/\{index\}/, currentIndex + 1)
							.replace(/\{hideBtn\}/, currentItem.hideBtn)
							.replace(/\{id\}/, currentItem.id);
				})

				$(container).html(html);
				$(container).owlCarousel({
					nav: true,
					navText: ['', ''],
					loop: true,
					autoplay: true,
					autoplayTimeout: 3500,
					autoplayHoverPause: true,
					items: 1
				});

				var owlCarouselDL = $(container);
				owlCarouselDL.mouseover(function () {
					owlCarouselDL.trigger('stop.owl.autoplay');
				});
				owlCarouselDL.mouseleave(function () {
					owlCarouselDL.trigger('play.owl.autoplay');
				});

				$(".btn", container).hover(function () {
					if ($(this).parents(".item").hasClass("has-icon")) {
						var style = $(this).find(".icon").data('style');
						$(this).find(".icon").css('backgroundImage', 'url(' + LOC_CONTENT + 'icons/' + style + '_hover.png' + ')');
					}
				}, function () {
					if ($(this).parents(".item").hasClass("has-icon")) {
						var style = $(this).find(".icon").data('style');
						$(this).find(".icon").css('backgroundImage', 'url(' + LOC_CONTENT + 'icons/' + style + '.png' + ')');
					}
				})

				$(".js-dl-item").bind("click", function () {
					var dlIndex = $(this).data("index");
					var dlType = $(this).data("type");
					var dlSeoname = $(this).data("seoname") //Link: type = 2
					var dlLink = "";
					if (dlType === 2 || dlType === 4 || dlType === 6)  //Link, Channel, Category
					{
						if (dlType === 2) {
							dlLink = dlSeoname;
						} else if (dlType === 4) {
							dlLink = LOC_SERVER + 'channels?seoName=' + dlSeoname;
						} else if (dlType === 6) {
							dlLink = LOC_SERVER + 'category/' + dlSeoname;
						}
						ga('send', 'event', {
							eventCategory: 'Dynamic Lead',
							eventAction: 'click',
							eventLabel: dlIndex,
							hitCallback: function () {
								window.location.href = dlLink;
							}
						});
					} else if (dlType === 1) //program
					{
						ga('send', 'event', {eventCategory: 'Dynamic Lead', eventAction: 'click', eventLabel: dlIndex});
					}
				})

				$(window).resize(function () {
					$(".js-dl-item").each(function () {
						var $img = $(this).find(".js-img");
						var id = $(this).data("id");
						$img.attr("src", detectOrientForDl(mql, id));
					})
				});
			}
		} else {
			$(container).hide();
		}
	}
}

function customPlayProgram(seoname, purchaseType, tvRating) {
	var access = false;
	var purchaseTypeStr = purchaseType && purchaseType.toString();
	if ((!purchaseTypeStr)
			|| ((purchaseTypeStr === '1' || purchaseTypeStr === '2') && userType !== 'anonymous' && userType !== 'member')
			|| ((purchaseTypeStr === '3' || purchaseTypeStr === '4') && (userType === 'subscriber' || userType === 'trialsubscriber' || userType === 'sub_and_mvpd' || userType === 'trail_and_mvpd'))
			|| userType === 'vipuser'
	) {
		access = true;
	}

	if (access) {
		// Check program parental controls
		if (!!window.ParentalControls) {
			var parentalControls = new ParentalControls();
			parentalControls.check(tvRating, function () {
				play(seoname, null);
			}, null);
		} else {
			play(seoname, null);
		}
	} else {
		showAuthPopup(purchaseTypeStr);
	}
}

function detectOrientForDl(mql, id) {
	var img = "";
	if (isMobile && mql.matches) {
		img = LOC_CONTENT + 'dl/' + id + '_ph.jpg';
	} else {
		img = LOC_CONTENT + 'dl/' + id + '_pc.jpg';
	}

	return img;
}

$(document).ready(function () {
	//DL
	$('.js-home-dl').html('<div class="loading-wrap js-dl-loading"><div class="loading"></div></div>');
	if (isTVE)
	{
		document.addEventListener('mvpd.userStatusChecked', function () {
			initHomeDL();
		});
	}
	else
	{
		initHomeDL();
	}

	// Init SiteNavd
	var siteNav = new SiteNavigation();
	document.addEventListener('neu.userStatusChanged',function(){
		//Update SiteNav
		siteNav.refresh(currentMVPD.status);
	},false);
})
